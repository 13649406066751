import react, { useState } from "react";
import Slider from "../Slider"
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";



function Home (){
    const [ data, setData ] = useState({
        name: "",
        email: "",
        massege: ""
    }) ;
    
    const [errName, setErrName] = useState(false);
    const [errEmail, setErrEmail] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    
    const handlerChnage = (e) => {
        setData( { ...data, [e.target.name] :e.target.value });
    }
    
    const handleSubmit = (e) =>{
        e.preventDefault();
    
        if(data.name.trim() == '')
        {
            setErrName(true);
        }else{
            setErrName(false);
        }
        if(data.email.includes('@'))
        {
            setErrEmail(false);
        }else{
            setErrEmail(true);
        }
        
        if(data.name.trim() != "" && data.email.includes('@') === true)
        {
        //   console.log(data);
        const  url = "https://sbcel.in/API/form.php";
            axios.post(url, {
                name: data.name,
                email: data.email,
                message: data.massege
            })
            .then(response => {
            //    console.log(response.data.type);
               if(response.data.type == "success" )
               {
                   setData({
                       name: "",
                       email: "",
                       massege:""
                   });
                    setSuccess(true);
               }
    
            })
            .catch(error => {
                setError(true);
            })
            // console.log('form submited');
        }else{
            console.log('form error');
        }
    }

    return (
        <div>
            <Slider />
            <div id="Wrapper">
            <Container className="text-center mt-3">
                <Row>
                    <Col>
                        <h3 className="title-border">What We Do</h3>
                    </Col>
                </Row>
                <Row xs={1} md={4} lg={4} className="mb-2">
                    <Col>
                        <Card className="mb-2" >
                            <Card.Body>
                                <Card.Title>HR Consultants</Card.Title>
                                <Card.Text>
                                We at SBCEL, believe that it is always to go for local Employment Generation in every State for respective state level …
                                </Card.Text>
                                <Link to="/hr-services">More Details</Link>
                            </Card.Body>
                        </Card>   
                    </Col>
                    <Col>
                        <Card className="mb-2" >
                            <Card.Body>
                                <Card.Title>IT Solutions</Card.Title>
                                <Card.Text>
                                We have a dedicated team for Software / Application development and for Website Development. We can assure our clients …
                                </Card.Text>
                                <Link to="/IT-Solutions">More Details</Link>
                            </Card.Body>
                        </Card>   
                    </Col>
                    <Col>
                        <Card className="mb-2">
                            <Card.Body>
                                <Card.Title>Manpower Staffing</Card.Title>
                                <Card.Text>
                                We have been successfully catering big corporate houses for their manpower (IT and Non-IT) requirements for all their …
                                </Card.Text>
                                <Link to="/Manpower-Staffing">More Details</Link>
                            </Card.Body>
                        </Card>   
                    </Col>
                    <Col>
                        <Card className="mb-2">
                            <Card.Body>
                                <Card.Title>e-Governance Services</Card.Title>
                                <Card.Text>
                                We at SBC Exports Ltd., believes that the eGovernance Projects are extremely difficult to execute at the ground to exicute...
                                </Card.Text>
                                <Link to="/e-Governance-Services">More Details</Link>
                            </Card.Body>
                        </Card>   
                    </Col>
                </Row>

                <Row xs={1} md={4} lg={4} className="mb-2">
                    <Col>
                        <Card className="mb-2" >
                            <Card.Body>
                                <Card.Title>Web/Software Development</Card.Title>
                                <Card.Text>
                                SBCEL offers full lifecycle Application Development for our Corporate & Government clients including System
                                </Card.Text>
                                <Link to="/web-development">More Details</Link>
                            </Card.Body>
                        </Card>   
                    </Col>
                    <Col>
                        <Card className="mb-2" >
                            <Card.Body>
                                <Card.Title>Scanning & Digitization</Card.Title>
                                <Card.Text>
                                We at SBCEL, believe that it is always to go for local Employment Generation in every State for respective state level …
                                </Card.Text>
                                <Link to="/scanning-and-digitization">More Details</Link>
                            </Card.Body>
                        </Card>   
                    </Col>
                    <Col>
                        <Card className="mb-2">
                            <Card.Body>
                                <Card.Title>Training and Skill Development</Card.Title>
                                <Card.Text>
                                We believe that it is always to go for local Employment Generation in every State for respective state level …
                                </Card.Text>
                                <Link to="/training-and-skill-development">More Details</Link>
                            </Card.Body>
                        </Card>   
                    </Col>
                    <Col>
                        <Card className="mb-2">
                            <Card.Body>
                                <Card.Title>System Integration</Card.Title>
                                <Card.Text>
                                As a Systems Integrator we build computing systems for clients by combining required hardware and software products from ...
                                </Card.Text>
                                <Link to="/system-integration">More Details</Link> 
                            </Card.Body>
                        </Card>   
                    </Col>
                </Row>
                </Container>
                <Container className="text-center mt-5">
                    <Row >
                        <Col>
                            <h3 className="title-border">Client Testimonials</h3>
                        </Col>
                    </Row>
                    <Row xs={1} md={3} lg={3}>
                        <Col>
                            <Card style={{ border:"none" }}>
                                <img src="images/SBCclientCMS.png"  className="mx-auto" style={{height:"64px", width:"64px"}} />
                                <Card.Body>
                                    <Card.Title>CMS Computer Ltd</Card.Title>
                                    <Card.Subtitle>Director</Card.Subtitle>
                                    <Card.Text>
                                    <strong>"</strong>We needed a website that reflected our status and search engine optimized. SBCEL as our website designers and developers and they exceeded our expectations. We highly recommend them."
                                    </Card.Text>
                                    
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{ border:"none" }}>
                                <img src="images/SBCclientABM.png"  className="mx-auto" style={{height:"64px", width:"64px"}} />
                                <Card.Body>
                                    <Card.Title>ABM Knowledgeware</Card.Title>
                                    <Card.Subtitle>BDO</Card.Subtitle>
                                    <Card.Text>
                                    <strong>"</strong>After a long search for a online creative agency, we finally chose SBCEL to develop a website for our NGO and we are more than glad we did! The output of SBCEL's web design work. <strong>"</strong>
                                    </Card.Text>
                                    
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{ border:"none" }}>
                                <img src="images/SBCclientET.png"  className="mx-auto" style={{height:"64px", width:"64px"}} />
                                <Card.Body>
                                    <Card.Title>EurecaTech Group</Card.Title>
                                    <Card.Subtitle>CEO</Card.Subtitle>
                                    <Card.Text>
                                    <strong>"</strong>Excellent works as always, sbcel completed my task in no time and following my instructions in detail. I got best website from here. Thanks a lot.<strong>"</strong>
                                    </Card.Text>
                                    
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <div className="mt-5" style={{backgroundImage: "url(images/G5uYe1h.jpg)"}}>
                    <Container className="py-5">
                    <Row xs={1} md={2} lg={2}>
                        <Col>
                            <h5>Get in touch with us</h5>
                            <form>
                                <div class="mb-3">
                                    <label className="form-label">Your Name: (*)</label>
                                    <input 
                                    type="text" 
                                    name="name" 
                                    className="form-control"
                                    value={ data.name }
                                    onChange={ handlerChnage }   />
                                    {errName? <span className="formErr">Name Required!</span> : null }
                                </div>
                                <div class="mb-3">
                                    <label className="form-label">Your Email: (*)</label>
                                    <input 
                                    type="text" 
                                    name="email" 
                                    className="form-control"
                                    value={ data.email }
                                    onChange={ handlerChnage }  />
                                    {errEmail?<span className="formErr">Email Required!</span>: null}
                                    
                                </div>
                                <div class="mb-3">
                                    <label className="form-label">Massege: (*)</label>
                                    <textarea 
                                    name="massege" 
                                    className="form-control" 
                                    rows={3} 
                                    value={ data.massege }
                                    onChange={ handlerChnage }  /> 
                                    
                                </div>
                                <button type="submit" name="formsubmit" className="btn btn-success" onClick={ handleSubmit } >Send</button>
                                {success? <span style={{ color: "#fff", fontSize: "14px"}}> Mail Sent!</span> : null }
                                {error? <span style={{ color: "red", fontSize: "14px"}}> Server error! please refresh page and retry.</span> : null }
                            </form>
                        </Col>
                        <Col>
                        <h5>Corporate Office</h5>
                        <div>
                            <ul className="list-unstyled">
                                <li> <i class="fas fa-map-marked-alt"></i> 204, Second Floor, Ansal Chamber-II, 6, Bhikaji Cama Place New Delhi – 110066</li>
                                <li> <i class="fas fa-phone-square"></i> +91 011-4352 3695</li>
                                <li> <i class="fas fa-envelope-square"></i> info@sbcel.com</li>
                                <li> <i class="fab fa-chrome"></i> www.sbcel.in</li>
                            </ul>
                        </div>
                        <h5>Head Office</h5>
                        <div>
                            <ul className="list-unstyled pl-2">
                                <li> <i class="fas fa-map-marked-alt"></i> 49/95, Sahibabad Industrial Area Site 4, Sahibabad, Ghaziabad, Uttar Pradesh 201010</li>
                                <li> <i class="fas fa-phone-square"></i> +91 120 6500503</li>
                                <li> <i class="fas fa-envelope-square"></i> info@sbcel.com</li>
                                <li> <i class="fab fa-chrome"></i> www.sbcexportslimited.com</li>
                            </ul>
                        </div>
                        </Col>
                    </Row>
                    </Container>
                </div>


                
            </div>
        </div>
    )
}

export default Home;