import { Col, Container, Row } from "react-bootstrap";
import react, { useState } from "react";
import axios from "axios";


const Contact = () => {

    const [ data, setData ] = useState({
        name: "",
        email: "",
        massege: ""
    }) ;

    const [errName, setErrName] = useState(false);
    const [errEmail, setErrEmail] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const handlerChnage = (e) => {
        setData( { ...data, [e.target.name] :e.target.value });
    }

    const handleSubmit = (e) =>{
        e.preventDefault();

        if(data.name.trim() == '')
        {
            setErrName(true);
        }else{
            setErrName(false);
        }
        if(data.email.includes('@'))
        {
            setErrEmail(false);
        }else{
            setErrEmail(true);
        }
        
        if(data.name.trim() != "" && data.email.includes('@') === true)
        {
        //   console.log(data);
        const  url = "https://sbcel.in/API/form.php";
            axios.post(url, {
                name: data.name,
                email: data.email,
                message: data.massege
            })
            .then(response => {
                console.log(response.data.type);
               if(response.data.type == "success" )
               {
                setData({
                    name: "",
                    email: "",
                    massege:""
                });
                    setSuccess(true);
               }

            })
            .catch(error => {
                setError(true);
            })
            // console.log('form submited');
        }else{
            console.log('form error');
        }
    }

    


    return (
        <div>
             <Container className="my-5">
                <Row className="text-center">
                    <Col><h2 className="title-border">Contact Us</h2></Col>
                </Row>
                <Row xs={1} md={2} lg={2}>
                        <Col>
                            <h5>Get in touch with us</h5>
                            <form>
                                <div class="mb-3">
                                    <label className="form-label">Your Name: (*)</label>
                                    <input 
                                    type="text" 
                                    name="name" 
                                    className="form-control"
                                    value={ data.name }
                                    onChange={ handlerChnage }   />
                                    {errName? <span className="formErr">Name Required!</span> : null }
                                </div>
                                <div class="mb-3">
                                    <label className="form-label">Your Email: (*)</label>
                                    <input 
                                    type="text" 
                                    name="email" 
                                    className="form-control"
                                    value={ data.email }
                                    onChange={ handlerChnage }  />
                                    {errEmail?<span className="formErr">Email Required!</span>: null}
                                    
                                </div>
                                <div class="mb-3">
                                    <label className="form-label">Massege: (*)</label>
                                    <textarea 
                                    name="massege" 
                                    className="form-control" 
                                    rows={3} 
                                    value={ data.massege }
                                    onChange={ handlerChnage }  /> 
                                    
                                </div>
                                <button type="submit" name="formsubmit" className="btn btn-success" onClick={ handleSubmit } >Send</button>
                                {success? <span style={{ color: "green", fontSize: "14px"}}>Mail Sent!</span> : null }
                                {error? <span style={{ color: "red", fontSize: "14px"}}>Server error! please refresh page and retry.</span> : null }
                            </form>
                        </Col>
                        <Col>
                        <h5>Corporate Office</h5>
                        <div>
                            <ul className="list-unstyled">
                                <li> <i class="fas fa-map-marked-alt"></i> 204, Second Floor, Ansal Chamber-II, 6, Bhikaji Cama Place New Delhi – 110066</li>
                                <li> <i class="fas fa-phone-square"></i> +91 011-4352 3695</li>
                                <li> <i class="fas fa-envelope-square"></i> info@sbcel.com</li>
                                <li> <i class="fab fa-chrome"></i> www.sbcel.in</li>
                            </ul>
                        </div>
                        <h5>Head Office</h5>
                        <div>
                            <ul className="list-unstyled pl-2">
                                <li> <i class="fas fa-map-marked-alt"></i> 49/95, Sahibabad Industrial Area Site 4, Sahibabad, Ghaziabad, Uttar Pradesh 201010</li>
                                <li> <i class="fas fa-phone-square"></i> +91 120 6500503</li>
                                <li> <i class="fas fa-envelope-square"></i> info@sbcel.com</li>
                                <li> <i class="fab fa-chrome"></i> www.sbcexportslimited.com</li>
                            </ul>
                        </div>
                        </Col>
                    </Row>
                </Container>
        </div>
    )
}

export default Contact;