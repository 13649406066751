import { Col, Container, Row } from "react-bootstrap";

const eGovernance = () => {
    return (
        <Container className="my-5">
            <Row className="text-center">
                <Col><h2 className="title-border">eGovernance Services</h2></Col>
            </Row>
            <Row>
            <div style={{textAlign: "justify"}}>
            <p><span style={{color: "#808080"}}><strong><em>SBCEL</em></strong></span>has been a strong IT partner to many of our customers across India. Our goal is to continuously improve our processes to make our clients feel value for money. Our philosophy is that the Client should feel worth doing business with us. We cannot restrict ourselves into a specific domain and would definitely expand in other areas to support our clients to support nation and Indian youth.<span style={{color: "#1da838"}}><strong><em> <span style={{color: "#808080"}}>E-Governance Services</span></em></strong></span></p>
            <hr />
            <p><span style={{color: "#808080"}}><strong><em>SBCEL</em></strong></span>provides end to end solutions in E-Governance by involving all stake holders which include governments, public sector entities other agencies to offer IT-based governance solutions that work for all. Our eGovernance solutions promote efficiency, transparency and policy effectiveness, enabling effective governance in areas that are essential to citizens and consumers.</p>
            <p></p>
            <p><span style={{color: "#808080"}}><strong><em>SBCEL</em>Service Offerings in E-Governance</strong></span></p>
            <ul>
            <li>Strategy Consulting
            <ul>
            <li>Research and Planning for e-Governance</li>
            <li>Evolving e-Governance Roadmaps</li>
            <li>Designing of IT roadmap</li>
            <li>Setting up large Information Infrastructure projects</li>
            <li>Monitoring and Evaluation framework</li>
            </ul>
            </li>
            </ul>
            <ul>
            <li>Project Consulting
            <ul>
            <li>Study of As-is Processes</li>
            <li>Defining To-be Processes</li>
            <li>Survey from across the India</li>
            <li>Stakeholder consultation</li>
            <li>Development of vision, mission and objectives</li>
            <li>Identification and prioritization of service</li>
            <li>Definition of service levels</li>
            <li>Develop a Proof of concept for the idea</li>
            <li>Program Management</li>
            </ul>
            </li>
            </ul>
            <p></p>
            <h3><span style={{color: "#1da838"}}><strong><em><i class="fa fa-graduation-cap"></i> <span style={{color: "#808080"}}>E-Governance – Training and Implementation / Rollout</span></em></strong></span></h3>
            <hr />
            <p><span style={{color: "#808080"}}><strong><em>SBCEL</em></strong></span>believes that any eGovernance / Corporate Project can only be considered successful when it goes live for actual beneficiaries for whom the project is made. We also know that the implementation / Rollout is the toughest part of any project and hence extra efforts are required to manage such projects successfully. We are managing some of the Projects of such kind for some of our clients.</p>
            <p>We train our Teams on different skills for different Projects to work under Projects. We have our dedicated teams who can manage projects at any level at any corner of India. We prepare fresh Indian resources for these projects from the corresponding stated and assign them the specific.</p>
            <p>We can work for National eGovernance Projects (NeGP) for their implementation at any level. We own our job portal and hence we not only have strength of resources but also have a huge database of Indian resources from every states of India. We also have our Skill Development Centers for unemployed Indian Youths.</p>
            <p>We are providing accelerated deployment of resources at multi location, multi technology and service rollouts for government and commercial organizations. We replicate newer applications, services, and solutions to the grass root levels, i.e. to the last mile at Village /Block / Panchayat levels across India.</p>
            <p></p>
            <h3><span style={{color: "#1da838"}}><strong><em><i class="fa fa-html5"></i> <span style={{color: "#808080"}}>Web Based Software Application &amp; Website Development</span></em></strong></span></h3>
            <hr />
            <p><span style={{color: "#808080"}}><strong><em>SBCEL</em></strong></span>offers full lifecycle application development for our government and corporate clients including Project management, Systems engineering, Architecture design, and application development, Documentation, Training and Implementation followed by ongoing support and maintenance. We offer these services bundled with our experienced professionals across all industry verticals in India.</p>
            <p>We have our Separate teams dedicated for Software / Application development and for Website Development. We can assure our clients for the unique designs and best possible features in their websites at best possible time frames.</p>
            <h3></h3>
            <h3><em><strong><span style={{color: "#808080"}}> Manpower Staffing and Skill Development</span></strong></em></h3>
            <hr />
            <p><span style={{color: "#808080"}}><strong><em>SBCEL</em></strong></span>in addition to Software Development is engaged with Big, Medium &amp; Small Corporate Houses and Government Organizations for staffing and outsourcing of work for software development and Project Implementation. <span style={{color: "#808080"}}><strong><em>SBCEL</em></strong></span>is providing manpower for all levels of professionals for all sort of work. These resources can be trained on any specific Technology / Work / Assignment at our Skill Development Centers. This also includes paid programs focusing on skill development areas like Soft Skills, Personality Development, Spoken English, Hospitality Attributes, Antiquates, Manners, Professionalism, Computer Proficiency, Project Management etc. to produce best results from the resources. The students enroll at our center for undergoing various levels of training programs which makes them suitable for future career prospects in respective industries</p>
            <p>We have major tie-ups with corporate houses for deployment of resources for all categories like:-</p>
            <ul>
            <li>Hardware / Software / Networking / Testing / Operation / Electrical Engineers</li>
            <li>Poly Technique / ITI / Networking Diploma Holder Resources</li>
            <li>Accounts / Finance / Banking / Financial Services / Insurance / Investments / eCommerce</li>
            <li>Front Office / Data Entry Operators / Office Assistants / Rollout Manpower</li>
            <li>Export / Import / Merchandising / Fashion Designing</li>
            <li>Hotel / Restaurant / Airline / Retail / Tour and Travel / BPO / LPO / KPO</li>
            <li>Human Resource / Sales / Marketing / General Administration / PR / Superintendent</li>
            <li>Medical / Pharmaceutical / Healthcare Representatives</li>
            <li>Manufacturing / Production / Maintenance / Automobile</li>
            </ul>
            <p></p>
            <h3><span style={{color: "#1da838"}}><strong><em><i class="fa fa-users"></i> <span style={{color: "#808080"}}>Human Resources – Assessment and Placement</span></em></strong></span></h3>
            <hr />
            <p>We at <span style={{color: "#808080"}}><strong><em>SBCEL</em></strong></span>, believe that it is always to go for local Employment Generation in every State for respective state level projects. We organize State Wide Recruitment / District Wide Recruitment / Block Wide Recruitment Drive for State Level Projects –</p>
            <ul>
            <li>We encourage every Government and Private Engineering / Degree / Diploma / Pharmacy Colleges to send their fresh / passed out students to appear for the written / interviews for our recruitment drive so that everyone is given a fare chance for selection. We also include local resources for the development of the respective area.</li>
            <li>We take the resources through the entire recruitment process – Written Tests (e.g. Analytical Ability, General Aptitude, Reasoning, and Picture Perception), Group Discussions, Personal Interviews etc.</li>
            <li>After we select the candidates, we take them through the complete training process to prepare them for different Projects.</li>
            <li>We guide remaining resources to prepare themselves for other upcoming Projects.</li>
            </ul>
            <p></p>
            <p></p>
            </div>
            </Row>
        </Container>
        )
    }
export default eGovernance;