import { Col, Container, Row } from "react-bootstrap";

const Scanning = () => {
    return (
        <Container className="my-5">
            <Row className="text-center">
                <Col><h2 className="title-border">Scanning And Digitization</h2></Col>
            </Row>
            <Row>
            <div style={{textAlign: "justify"}}>
                <h3>Advantages of Scanning and Digitization</h3>
                <p>Reduction in physical space requirements and a much quicker report search and retrieval approach are simply two of the many advantages of digitising your forms.</p>
                <p>Scanning and Digital Imaging is the conversion of physical documents and pictures of any kind, in any structure, into their digital equivalents. Reduction in physical space requisites and a so much quicker record search and retrieval are simply two of the numerous advantages of digitising your documents. SBCEL supplies complete imaging solutions and aims for operator-effective design and productivity. We work on client-distinct report management standards and, partnering with world leaders, present you potent solutions keeping in intellect present and future wants.</p>
                <h3>What are requisites for Digitization?</h3>
                <p>With the dramatic broaden in varieties of information and respective codecs, the have got to combine and share information throughout techniques has come to be relevant. For most companies, this entails gentle balancing of the tactics that move information between methods.</p>
                <p>Our solutions handle the above concern in an awfully potent and effective method:</p>
                <ol>
                <li><strong>Streamline business processes</strong></li>
                </ol>
                <ul>
                <li>Give expertise / knowledge to the correct man or woman at the right time.</li>
                <li>Streamline paper-founded approaches.</li>
                <li>Optimize processing time.</li>
                <li>Toughen productivity and efficiency.</li>
                </ul>
                <ol>
                <li><strong>Report administration</strong></li>
                </ol>
                <ul>
                <li>Archive and control documents in a structured and cozy manner.</li>
                <li>Secured your paper documents.</li>
                <li>Decrease hazard of lost / misplaced files.</li>
                </ul>
                <ol start="3">
                <li><strong>Information capture</strong></li>
                </ol>
                <ul>
                <li>Capture information from paper files, multimedia and digital content.</li>
                <li>Read know-how from scannable inputs like barcodes, QR codes etc.</li>
                <li>Change into and supply captured knowledge into various techniques: report administration, CRM, ERP, MIS, and so forth.</li>
                </ul>
                <ol start="4">
                <li>Continuous Availability</li>
                </ol>
                <ul>
                <li>Keep end users continually connected to their mission- imperative applications.</li>
                <li>Provision of robust excessive availability and disaster restoration solutions.</li>
                <li>No longer simply LAN founded; WAN-enabled.</li>
                </ul>
                <ol start="5">
                <li><strong>Lessen Paper Storage</strong></li>
                </ol>
                <p>90% of records handled in the office are paper-cantered. Digital documents can curb record space for storing via as much as 80 %. Now not best is cupboard space minimized, however access to “filed” documents is also extensively expanded.</p>
                <ol start="6">
                <li><strong>Eliminate guide Searches</strong></li>
                </ol>
                <p>Digital report administration can furnish an instant response to a question, bypassing the lengthy system of a paper search. Doing away with the manual search can increase patron carrier, which helps you offer consumers turbo response occasions.</p>
                <ol start="7">
                <li><strong>Accelerated expertise Availability – Multipoint access</strong></li>
                </ol>
                <p>Imaging solutions can develop from a single consumer to multiple customers across the group. With digital imaging, it is now not quintessential to shuffle files from one desk to an extra. Persons working in one-of-a-kind departments, areas, or places can all have entry to the equal knowledge through their desktops. Pictures are decentralized, and for this reason extra conveniently and without problems obtainable.</p>
                <p>Via the use of our On-Base patron App, you’ve the ability to open and consider a number of documents at the same time. If a co-employee wants a file you’re engaged on, that you may drag the file icon into the mailbox option and ship it to them, thereby making entry rapid and lowering time spent browsing.</p>
                <ol start="8">
                <li><strong>Broaden know-how security</strong></li>
                </ol>
                <p>Security of your documents and files is completed on many stages. Entry to information is comfortable and available handiest to authorized personnel by way of the usage of virtual exclusive Networks (VPNs).</p>
                <p>Digital document management can provide one more type of safety by means of disaster healing (DR). Once the info is transformed to digital structure, a backup replica is saved in a catastrophe restoration vault. Must an unlucky disaster occur, SBCEL may have you operational once more inside a number of hours, removing costly downtime.</p>
                <ol start="9">
                <li><strong>Cut back Storage expenses</strong></li>
                </ol>
                <p>The cost of filling and maintaining a filing cabinet is dazzling. There are fees associated with apparatus, house, and the salaries it takes to keep the filing cabinet</p>
                <ol start="10">
                <li><strong>Increase supporter service</strong></li>
                </ol>
                <p>A report management method can empower your manufacturer’s workers with the potential to locate and retrieve files right away. This in flip enables instantaneous response to consumer questions and inquiries with confined downtime.</p>
                <p>SBCEL can setup your document administration resolution in a technique that compliments your provider drive. The structured, listed means wherein understanding is saved and retrieved ensures consumers face minimal wait for the period of support calls, because the carrier agent can swiftly and efficaciously access all pertinent details and directions.</p>
                </div>
            </Row>
        </Container>
        )
    }
export default Scanning;