import { Col, Container, Row } from "react-bootstrap";

const Training = () => {
    return (
        <Container className="my-5">
            <Row className="text-center">
                <Col><h2 className="title-border">Training And Skill Development</h2></Col>
            </Row>
            <Row>
            <div style={{textAlign: "justify"}}>
                Skill Development means developing oneself and his/her skill sets with assessing which skills are important for your desired career development to add value for the organization and for their own career development. Research says that<p></p>
                <ul>
                <li>On the Job Training and Action Learning (including development experiences like managing a project, serving on a cross-functional team, taking on a new task, job shadowing, job rotation, etc. – This makes you get 70% Output)</li>
                <li>Interactive Sessions with Others (includes having a mentor, being a mentor, coaching, participating in communities of practice, serving as a leader in a staff organization, etc. – This makes you get 20 % Output)</li>
                <li>Training (including classes, seminars, webinars, podcasts, conferences, etc. – This makes you get 10 % Output).</li>
                </ul>
                <p>SBCEL in addition to Software Development is engaged with Big, Medium  Small Corporate Houses and Government Organizations for staffing and outsourcing of work for software development and Project Implementation. SBCEL is providing manpower for all levels of professionals for all sort of work. These resources can be trained on any specific Technology / Work / Assignment at our Skill Development Centres across India. This also includes paid programs focusing on skill development areas like Soft Skills, Personality Development, Spoken English, Hospitality Attributes, Etiquette, Manners, Professionalism, Computer Proficiency, Project Management etc. to produce best results from the resources. The students enrol at our centres for undergoing various levels of training programs which makes them suitable for future career prospects in respective industries.</p>
                <p>We train our Teams on different skills for different Projects to work under Projects. We have our dedicated teams who can manage projects at any level at any corners of India. We prepare fresh Indian resources for these projects from the corresponding stated and assign them the specific tasks.</p>
                <p>We can work for National e-governance Projects (under NeGP) for their implementation at any level. We own our job portal and hence we not only have strength of resources but also have a huge database of Indian resources from every states of India. We also have our Skill Development Centres for unemployed Indian youths.</p>
            </div>
            </Row>
        </Container>
        )
    }

export default Training;