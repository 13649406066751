import { Col, Container, Row } from "react-bootstrap";

const manpower = () => {
    return (
        <Container className="my-5">
            <Row className="text-center">
                <Col><h2 className="title-border">Manpower Staffing</h2></Col>
            </Row>
            <Row>
            <div style={{textAlign: "justify"}}>
                <h3><span style={{color: "#1da838"}}><strong><em>Manpower Staffing and Skill Development</em></strong></span></h3>
                <hr />
                <p>SBCELin addition to Software Development is engaged with Big, Medium Small Corporate Houses and Government Organizations for staffing and outsourcing of work for software development and Project Implementation. SBCELis providing manpower for all levels of professionals for all sort of work. These resources can be trained on any specific Technology / Work / Assignment at our Skill Development Centres. This also includes paid programs focusing on skill development areas like Soft Skills, Personality Development, Spoken English, Hospitality Attributes, Antiquates, Manners, Professionalism, Computer Proficiency, Project Management etc. to produce best results from the resources. The students enroll at our center for undergoing various levels of training programs which makes them suitable for future career prospects in respective industries</p>
                <p><span style={{color: "#1da838"}}><strong>We have major tie-ups with corporate houses for deployment of resources for all categories like:</strong></span></p>
                <ul>
                <li>Hardware / Software / Networking / Testing / Operation / Electrical Engineers</li>
                <li>Poly Technique / ITI / Networking Diploma Holder Resources</li>
                <li>Accounts / Finance / Banking / Financial Services / Insurance / Investments / eCommerce</li>
                <li>Front Office / Data Entry Operators / Office Assistants / Rollout Manpower</li>
                <li>Export / Import / Merchandising / Fashion Designing</li> 
                <li>Hotel / Restaurant / Airline / Retail / Tour and Travel / BPO / LPO / KPO</li>
                <li>Human Resource / Sales / Marketing / General Administration / PR / Superintendent</li>
                <li>Medical / Pharmaceutical / Healthcare Representatives</li>
                <li>Manufacturing / Production / Maintenance / Automobile</li>
                <li>Permanent Staffing</li>
                <li>Manpower Staffing (IT Non-IT)</li>
                </ul>
                <p></p>
                <h3><span style={{color: "#1da838"}}><em><i class="fa fa-briefcase"></i> <span style={{color: "#1da838"}}>Permanent Staffing</span></em></span></h3>
                <hr />
                <p><strong>Executive Search</strong></p>
                <p>Organizations keep looking at hiring good leaders as part of their growth strategy. The challenge is to get appropriate alignment of leader’s aspirations with the directions of the organization. Increasingly, the search has become very specialized and focused. We, bring in expertise, network and market research to our clients in identifying suitable leaders for their organization.</p>
                <blockquote><p>Count on SBCELfor proven expertise in recruiting, assessing and qualifying candidates for your business needs. When your goal is to attract top talent across geographic regions, you need a recruiting partner that can mirror your organization’s reach and local presence; we bring you the specialized offerings and the right talent to keep your workforce agile and your initiatives on track.</p></blockquote>
                <p>As a preferred search partner it is our ability to think broadly, identify and motivate the next generation leaders to join our client organization that will compete in this new era of complex and diverse customer markets. We have rich industry expertise and follow global standards for acquiring talent across leadership levels our recruiting methods and assessment techniques identify the right professional talent for any position.</p>
                <p></p>
                <h3><span style={{color: "#1da838"}}><strong><i class="fa fa-black-tie"></i> <span style={{color: "#1da838"}}>Professional Resourcing</span></strong></span></h3>
                <hr />
                <p>Talent overtakes capital as the key economic differentiator. More than ever, business growth depends on putting the right people in place—the in-demand professional talent that sets your organization apart from the competition. That’s why companies turn to us. We have the deep industry knowledge to understand the challenges your business is facing and give you access to the professional talent and expertise you need to seize new opportunity and launch your business forward.</p>
                <p>We offer cost-effective, scalable solutions and flexible delivery models tailored to your specific objectives and organizational style. You get the degree of control you want and the expertise you need to keep your initiatives on track and your business growing., we give you access to one of the largest network of highly specialized Professionals and we have the experience and expertise to help you build your workforce in the way that delivers the best options for your business</p>
                <p>Our proprietary assessment methods identify the right professional talent for any position. We drill down into the details of each candidate’s profile and background to align their specific talents and passion with your business goals and culture.</p>
                <p><span style={{color: "#1da838"}}><strong>Permanent Placement</strong></span></p>
                <p>With breakthrough permanent placement solutions, you can access the highly specialized talent you need to create a sustainable competitive advantage. With a deep understanding of your industry, processes and business issues, we bring specialized solutions and talent together to take on your mission-critical and day-to-day initiatives, so you can focus on what matters most.</p>
                <p></p>
                <h3><span style={{color: "#1da838"}}><strong><em><i class="fa fa-user-plus"></i> <span style={{color: "#1da838"}}>Manpower Staffing (IT Non-IT)</span></em></strong></span></h3>
                <hr />
                <p>We have been successfully catering to the Corporates, Government Departments and PSUs for their manpower requirements across India for implementation of e-Governance Projects.</p>
                <p>SBCELis providing accelerated deployment of resources for Rollout and Implementation at multi location, multi technology and service rollouts for government and commercial organizations. We replicate newer applications, services, and solutions to the grass root levels, i.e. to the last mile at Villages/Block levels across India.</p>
                <p>The professional services are structured around our offerings covering various cutting-edge technology platforms. We offer the following to our customers:</p>
                <ul>
                <li>Customization services</li>
                <li>Training long term Skill up-gradation programs.</li>
                <li>Technologies refresh services.</li>
                <li>Pre-installation Survey, GIS mapping, locational services, vehicle tracking, LiDAR3D City Modelling.</li>
                <li>Specialized customer support programs.</li>
                </ul>
                <p></p>
                </div>
            </Row>
        </Container>
        )

    }

export default manpower;