import react from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import PageHeader from "./PageHeader";
import About from "./pages/About";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Career from "./pages/Career";
import Home from "./pages/Home"

import PageFooter from "./PageFooter";
import HrServices from "./pages/Hrservices";
import manpower from "./pages/Manpower";
import Itsolution from "./pages/Itsolution";
import eGovernance from "./pages/Egovermence";
import Web from "./pages/Web";
import Scanning from "./pages/Scanning";
import Training from "./pages/Training";
import System from "./pages/System";



function App() {
  return (
    <div className="App">
      <Router>
      <PageHeader />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" exact component={About} />
          <Route path="/services" handler={HrServices} />
          <Route path="/portfolio" component={Blog} />
          <Route path="/contact" component={Contact} />  
          <Route path="/career" component={Career} />  

          <Route path="/Manpower-Staffing" component={manpower} />
          <Route path="/IT-Solutions" component={Itsolution} />
          <Route path="/e-Governance-Services" component={eGovernance} />
          <Route path="/web-development" component={Web} />
          <Route path="/scanning-and-digitization" component={Scanning} />
          <Route path="/training-and-skill-development" component={Training} />
          <Route path="/system-integration" component={System} />
          <Route path="/hr-services" component={HrServices} />
        </Switch>
        
        <PageFooter />
      </Router>
    </div>
  );
}
export default App;
