import { Col, Container, Row } from "react-bootstrap";
import react, { useState } from "react";
import axios from "axios";


const Career = () => {

    const [ data, setData ] = useState({
        name: "",
        email: "",
        massege: ""
    }) ;

    const [errName, setErrName] = useState(false);
    const [errEmail, setErrEmail] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const handlerChnage = (e) => {
        setData( { ...data, [e.target.name] :e.target.value });
    }

    const handleSubmit = (e) =>{
        e.preventDefault();

        if(data.name.trim() == '')
        {
            setErrName(true);
        }else{
            setErrName(false);
        }
        if(data.email.includes('@'))
        {
            setErrEmail(false);
        }else{
            setErrEmail(true);
        }
        
        if(data.name.trim() != "" && data.email.includes('@') === true)
        {
        //   console.log(data);
        const  url = "https://sbcel.in/API/form.php";
            axios.post(url, {
                name: data.name,
                email: data.email,
                message: data.massege
            })
            .then(response => {
            //    console.log(response.data.type);
               if(response.data.type == "success" )
               {
                setData({
                    name: "",
                    email: "",
                    massege:""
                });
                    setSuccess(true);
               }

            })
            .catch(error => {
                setError(true);
            })
            // console.log('form submited');
        }else{
            console.log('form error');
        }
    }

    


    return (
        <div>
             <Container className="my-5">
                <Row className="text-center">
                    <Col><h2 className="title-border">Career</h2></Col>
                </Row>
                <Row xs={1} md={2} lg={2}>
                        <Col>
                            <h5>Get in touch with us</h5>
                            <form>
							    <div class="mb-3">
                                    <label className="form-label">Apply Post : (*)</label>
                                    <select className="form-control">
									  <option value="">Select Post </option>
									  <option value="Accountant">Accountant</option>
									</select>
                                </div>
                                <div class="mb-3">
                                    <label className="form-label">Upload Resume: (*)</label>
                                    <input 
                                    type="file" 
                                    name="name" 
                                    className="form-control"
                                    value={ data.name }
                                    onChange={ handlerChnage }   />
                                    {errName? <span className="formErr">Name Required!</span> : null }
                                </div>
                                <div class="mb-3">
                                    <label className="form-label">Massege: (*)</label>
                                    <textarea 
                                    name="massege" 
                                    className="form-control" 
                                    rows={3} 
                                    value={ data.massege }
                                    onChange={ handlerChnage }  /> 
                                    
                                </div>
                                <button type="submit" name="formsubmit" className="btn btn-success" onClick={ handleSubmit } >Send</button>
                                {success? <span style={{ color: "green", fontSize: "14px"}}>Mail Sent!</span> : null }
                                {error? <span style={{ color: "red", fontSize: "14px"}}>Server error! please refresh page and retry.</span> : null }
                            </form>
                        </Col>
                        <Col>
                        <h5>JOB POSTING</h5>
                        <div>
                            <ul className="list-unstyled">
                                <li>  Project Name: SC ST Development Department, Chhattisgarh for Eklavya Model Residential School in Chhattisgarh</li>
                                <li> Post Name: Accountant</li>
                                <li> Experience: 2 Years’ experience</li>
                                <li> Qualification: Graduate in Commerce / Accounts</li>
								<li> Scope of Work: Working as GST/TDS/Tally/PFMS & Candidates should know typing Hindi & English with 40 w.p.m. </li>
								<li> CTC: 32K   </li>
								<li> Location: Chhattisgarh (All district)  </li>
								<li> Candidates can upload their profile in PDF/Word format and 60 words type their skills and interest. </li>



                            </ul>
                        </div>
                        </Col>
                    </Row>
                </Container>
        </div>
    )
}

export default Career;