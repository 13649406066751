import { Carousel } from "react-bootstrap";
import react, {useState} from "react"

const Slider = () => {


    return(
        <Carousel>
        <Carousel.Item>
          <img
          
            className="d-block w-100"
            src="images/web_dev-3.png"
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>Website Development in 5 Step</h3>
            {/* <p>Get all design from here with multiple choice</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/web_seo.png"
            alt="Second slide"
          />
      
          <Carousel.Caption>
          <h3>DESIGN BY SBCEL</h3>
            <p>Get all design from here with multiple choice</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images/new_design-2.png"
            alt="Third slide"
          />
      
          <Carousel.Caption>
            <h3>Search Engine Optimization</h3>
            <p>Get result via Search engine</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    )

}



export default Slider;