import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const PageFooter = () => {
    return (
        <div>
            <footer className="footer mt-auto py-3 bg-light">
                <Container className="">
                    <Row xs={1} md={4} lg={4}>
                        <Col>
                        <h5>Pages</h5>
                        <div>
                            <ul className="list-unstyled">
                                <li><Link to="/">Home</Link> </li>
                                <li><Link to="/about">About</Link> </li>
                                <li><Link to="/hr-services">Services</Link> </li>
                                <li><Link to="/contact">Contact</Link> </li>
                                <li><a href="https://sbcel.in/jobs/careers">Career</a> </li>
                                
                            </ul>
                        </div>
                            
                        </Col>
                        <Col>
                        <h5>Services</h5>
                        <div>
                            <ul className="list-unstyled">
                                <li><Link to="/hr-services">HR Consultants</Link> </li>
                                <li><Link to="/IT-Solutions">IT Solutions</Link> </li>
                                <li><Link to="/Manpower-Staffing">Manpower Staffing</Link> </li>
                                <li><Link to="/e-Governance-Services">e-Governance Services</Link> </li>
                                <li><Link to="/web-development">Web/Software Development</Link> </li>
                                <li><Link to="/scanning-and-digitization">Scanning & Digitization</Link> </li>
                                <li><Link to="/training-and-skill-development">Training and Skill Development</Link> </li>
                                <li><Link to="/system-integration">System Integration</Link> </li>

                                
  
                            </ul>
                        </div>
                        </Col>
                        <Col>
                        <h5>Other Links</h5>
                        <div>
                            <ul className="list-unstyled">
                                <li><a href="https://sbcexportslimited.com/Notices.php">Notices</a> </li>
                                <li><a href="https://sbcexportslimited.com/Annual-Reports.php">Annual Reports</a> </li>
                                <li><a href="https://sbcexportslimited.com/board-of-director.php">Board Of Director</a> </li>
                                <li><a href="https://sbcexportslimited.com/Financial-Result.php">Financial Result</a> </li>
                                <li><a href="https://sbcexportslimited.com/shareholding-pattern.php">Shareholding Pattern</a> </li>
                                <li><a href="https://sbcexportslimited.com/Annual-General-Meeting.php">Genral Meeting</a> </li>
                                
  
                            </ul>
                        </div>
                        </Col>
                        <Col>
                        <h5>Certified</h5>
                        <div>
                            <img src="images/Indicsoft-ISO-9001-2008-Certified.png" style={{height:"60px"}}  />
                            <img src="images/sbcel-cmmi-logo.png" style={{height:"60px"}} />
                            <img src="images/CMMI LEVEL 5.png" style={{height:"60px"}} />
                        </div>
                        </Col>
                    </Row>
                </Container>
                
                </footer>
                <div className="footer mt-auto bg-dark text-center" style={{lineHeight: "2rem"}}>
                    <span className="text-muted">Copyright © 2021 SBC Exports Limited | All Right Reserved.</span>
                </div>
        </div>
    )

}

export default PageFooter;