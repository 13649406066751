import { Col, Container, Row } from "react-bootstrap";

const Web = () => {
    return (
        <Container className="my-5">
            <Row className="text-center">
                <Col><h2 className="title-border">Web/Software Development</h2></Col>
            </Row>
            <Row>
            <div style={{textAlign: "justify"}}>
                
                <hr />
                <p>SBCELis an established IT company that sets your business in the right direction and provides end to end web development services.The development strategy especially affects the business and management goals that’s why for better functioning of the web solutions according to its business model, our team of professional developers and project managers ensure whether the web development needs of the client are carefully studied.</p>
                <p>We carry out an in-depth analysis of the client’s requirements and properly understand the business model to provide the best-customized web development services along with following the predetermined time bound manner. We have been consistently developing and delivering many web development projects which have effectively managed the complex requirements and issues of our clients.</p>
                <p>SBCEL has best analytical designers who will help you in knowing that which website design will suit you. They will develop plans after going through your business goals, business area and your competitors. All website designs are made in keeping the mind about the needs of client’s business due to this it is needed to first conduct research on the business related strategies.</p>
                <p><u></u>We are specialized in custom development of the website along with the designing in open sources as WordPress for effective representation of your business website. Moreover, the company also offers Custom CMS solution to the clients as content forms the lifeline of any website to bring it to the forefront of the website users and visitors.</p>
                <p>Google always displays the website on the first page of search option with more effective and informative contents as it keeps crawling day and night for searching new contents. So, updating the right content every day is a very important part of work for administrator so that the search result shows the website on the very first page.</p>
                <p>Here are the top listing of the website solutions, designing and development services that you will be amazed to find with us:</p>
                <ul>
                <li>Website design and development with GIGW compliances</li>
                <li>Corporate Website design and development</li>
                <li>Static/Dynamic Website Development</li>
                <li>Responsive Website Development</li>
                <li>Customized CMS Implementations</li>
                <li>Custom Database Applications</li>
                <li>Custom Web Programming</li>
                </ul>
                <p></p>
                <h3><strong><em> Application Development Services</em></strong></h3>
                <hr />
                <p>SBCELoffers full lifecycle Application Development for our Corporate  Government clients including System Study, SRS Preparation, Architecture design, Application Development, Project Management, Testing  QA, Security Audit, Documentation, Training and Implementation followed by on-going support and maintenance. We offer these services bundled with our experienced professionals across all industry verticals in India.</p>
                <blockquote><p>Web based applications are the ultimate way to take advantage of today’s technology to enhance your organizations productivity  efficiency by accessing the information remotely 24 X 7 and sharing the information they have. This gives you an opportunity to access your business information from anywhere in the world at any point of time. It also facilitates you to save time  money and improve the interactivity with your customers and partners.</p></blockquote>
                <p>The design, development, testing, de-bugging and deployment of customized and tailored requirements specified by respective customers, application designed on standard platforms for actionable insights, GIS, GPS and allied applications and specific website development.</p>
                <p>We have separate teams dedicated for Web Designers, Developers, Programmers, Security Auditors for Website Development for Indian Government Websites which may rather follow GIGW Compliance). We can assure our clients for the unique designs and best possible features in their websites at best possible time frames.</p>
                <p>Custom Application Development is fast gaining ground in the up-coming business working models. These applications provide services to give you an upper hand and ease of work and keep your business and requirements up-to-date.</p>
                <p>The custom applications create efficiency as per your requirements and time saving. At SBCEL, we understand the importance of the custom application development. We have a methodology of working in with our clients to get a clear understanding of their business needs as well as requirements to develop the custom application that would get the maximum results for the clients.</p>
                <p>Our dedicated team of professionals develops the best custom applications that perfectly suit your business requirements. It is vital to determine what particular application can meet up to the optimum solution for the organization. Our professionals have the absolute understanding the best software and technologies that would suit the needs of your business systems and would get the maximum results by minimum inputs.</p>
                <p><strong>Customized Software Development:</strong></p>
                <p>Customized Software Development or Custom Software Engineering is essential when the existing software solutions are not able to meet all the requirements of the customer or when the customer believes that his business needs are unique. The Customized Software Development may be associated with the development of the software just for a single customer not for mass customers. The main objective of custom software development is not to develop general-purpose software but to develop the unique software particularly for a single customer.</p>
                <p><strong>Custom Solutions for Software Development:</strong></p>
                <ul>
                <li>Enterprise Business Solutions</li>
                <li>Systems Software</li>
                <li>Client-Server Applications</li>
                <li>Web-based Applications</li>
                </ul>
                <p><strong>Custom Application Development:</strong></p>
                <p>Custom Application Development’s fast gaining ground in the up-coming business working models. These applications provide services to give you an upper hand and ease of work and keep your business and requirements up-to-date. The custom applications create efficiency as per your requirements and time saving. At SBCEL, we understand the importance of the custom application development. We have a methodology of working in with our clients to get a clear understanding of their business needs as well as requirements to develop the custom application that would get the maximum results for the clients.</p>
                </div>
            </Row>
        </Container>
        )
    }
export default Web;