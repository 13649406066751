import { Col, Container, Row } from "react-bootstrap";


const About = () => {
    return (
        <div>
            <Container className="my-5">
                <Row className="text-center">
                    <Col><h2 className="title-border">AboutUS</h2></Col>
                </Row>
                <Row>
                    <hr />
                    <blockquote><p><strong>"We are an young, vibrant and fastest growing Group consists of Gamut of Services under the umbrella of SBC Group (www.sbcel.com)."</strong></p></blockquote>
                    
                    <p>25 Years ago, we started our business as one of the leading manufacturers and exporters of handmade Indian Carpets in wool and silk. We always bring age of old tradition of Indian carpet weaving and have our large manufacturing unit and warehouse in Mirzapur (UP). Our strength is continuous improvements and capability to create something new in traditional way. Our customer caring approaches have made us one of successful exporters from India. Due to hard work of our esteemed associated people we have attained very renowned name in the carpet industry. We are also an esteem member of Carpet Export Promotion Council, INDIA.</p>

                    <p>After a long journey of business we ventured into IT and eGovernance and established SBC Exports Limited as a company registered under the Companies Act, 1956 under Registrar of Companies. We at SBCEL, provides the services in the area of Software Development, Information Technology, Management Consultancy, IT consulting, e-Governance Training, Implementation, Rollout of Services, Skill Development and Best HR Practices, Manpower Staffing. We provide services to different industries to support their IT needs and at the same time we also support them while providing them the trained resources for their specified needs.</p>

                    <p>We at SBCEL, believes that the eGovernance Projects are extremely difficult to execute at the ground zero level as there is a huge gap between the companies who wins the projects and their level of presence at very small levels (e.g. at Panchayat levels). Here we are committed to take these challenges to support those big Companies to execute the success of those projects of National importance. This can only happen when we (mid core companies) come forward and support big National Projects at small levels.</p>
                    
                    <h3>At SBCEL, our service range includes but not limited to</h3>
                    <p>SBC has been a strong IT partner to many of our customers. Our goal is to continue to develop strong skills in the existing areas of engagement as well as expand onto other areas in future.</p>
                    <p>Following are some of our focused service areas of engagement</p>
                    <ul>
                        <li>e-Governance Projects – Training and Implementation (Rollout of Services)</li>
                        <li>Manpower support (IT and Non-IT) for Speedy Implementation and Rollout of Projects (MMPs)</li>
                        <li>Professional Support Services for Application Development / Web Development</li>
                        <li>Manpower Categories include Data Entry Operator / Operation Manager / Operation Assistant / IT Assistants / Office Assistant / Multi-Tasking Staff / Team Leader / Software Developer etc</li>
                        <li>Web Based Software / Application Development / Website Development (GIGW Compliance)</li>
                        <li>Scanning and Digitization / Document Management System</li>
                        <li>Skill Development (Registered with DDUGKY)</li>
                        <li>Training and Development (Soft Skill Trainings)</li>
                        <li>Security &amp; Surveillance Projects</li>
                        <li>System Integration</li>
                        </ul>
                </Row>
            </Container>
        </div>
    )
}

export default About;