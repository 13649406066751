import react from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

const pageHeader= () => {
    return (
        <div>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
            <Container>
            <Navbar.Brand href="#home"><img src="images/sbclogo.png"  alt="sbc-logo" style={{width:"138px"}}></img></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav float-end" />
            <Navbar.Collapse id="responsive-navbar-nav " className="justify-content-end">
                <Nav className="float-end">
                <Nav.Link as={Link} to="/">Home</Nav.Link>
                <Nav.Link as={Link} to="/about">About Us</Nav.Link>
                <NavDropdown title="Services" id="collasible-nav-dropdown">
                    <NavDropdown.Item as={Link} to="/hr-services">HR Consultants</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/IT-Solutions">IT Solutions</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/Manpower-Staffing">Manpower Staffing</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/e-Governance-Services">e-Governance Services</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/web-development">Web/Software Development</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/scanning-and-digitization">Scanning & Digitization</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/training-and-skill-development">Training and Skill Development</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/system-integration">System Integration</NavDropdown.Item>
                </NavDropdown>
				<Nav.Link  href="https://sbcel.in/jobs/careers">Career</Nav.Link>
                <Nav.Link as={Link} to="">Contact Us</Nav.Link>
                </Nav>
                
            </Navbar.Collapse>
            </Container>
            </Navbar>
</div>
    )
}

export default pageHeader;