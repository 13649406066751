import { Col, Container, Row } from "react-bootstrap";

const System = () => {
    return (
        <Container className="my-5">
            <Row className="text-center">
                <Col><h2 className="title-border">System Integration</h2></Col>
            </Row>
            <Row>
            <div style={{textAlign: "justify"}}>
                <p>As a Systems Integrator we build computing systems for clients by combining hardware and software products from multiple Original Equipment Manufacturers (OEMs). We align cheaper, pre-configured components and off-the-shelf software to meet key business goals, as opposed to more expensive, customized implementations that may require original programming or manufacture of unique components. Creation of these information systems may include designing or building a customized architecture or application, integrating it with new or existing hardware, packaged and custom software, and communications infrastructure. This ensures tangible results quickly and enables real-time Decision Support System for Decision Making to achieve operational excellence irrespective of the organization sizes while maximizing efficiency to enable technology enabled business transformation.The process of inter-connecting multi-technology platforms or platforms from multiple OEMs to process information and offer the pre-defined business outcome to customers or to maintain equilibrium in communication flow across platforms while processing, storing/achieving or retrieving information.</p>
                <p>SBCEL  with its strategic alliance partners, offer multi-platform, multi-technology, application design, development, testing services and asset-based solutions in niche segments through turnkey projects for mission critical systems. SBC has also been expanding its service presence in international markets offering offshoring advantages and delivering value through service level-based and project scope-based deliveries.</p>
                <p>We deploy, manage and optimize technology to help clients gain more from their investments in infrastructure communications with some of our key deliverables like:</p>
                <ul>
                <li>Providing secure, unimpeded connectivity between employees and customers</li>
                <li>Connecting multi-technology platforms to provide pre-defined business outcomes</li>
                <li>Operate and maintain the systems for customers when necessary</li>
                </ul>
                <p><strong><em><u>  </u></em></strong></p>
            </div>
            </Row>
        </Container>
        )
    }
export default System;