import { Col, Container, Row } from "react-bootstrap";

const HrServices = () => {
    return (
        <Container className="my-5">
            <Row className="text-center">
                <Col><h2 className="title-border">HR Services</h2></Col>
            </Row>
            <Row>
            <div style={{textAlign: "justify"}}>
                <h3><strong><i className="fa fa-hand-o-right"></i> HR Services</strong></h3>
                <hr />
                <p>Over the past few years,<strong>HR outsourcing</strong>has become a dependable way for organisations to reduce operating costs, streamline internal processes, and focus on core business operations. With exposure in almost all verticals of <strong>HR services</strong>outsourcing SBCELhave in place the necessary processes,<strong>HR consultants</strong>, knowledge, technology, and best practices to support you in all aspects of human resources and employment administration.</p>
                <p>Our<strong>HR solutions</strong>are designed to provide companies with a single resource which is dedicated to managing and supporting all HR and employment processes, thus allowing them to focus on the key business areas.</p>
                <p></p>
                <p><strong>We have the capability to undertake most of the HR administrative activities such as:</strong></p>
                <ul>
                <li>EDB and HRM services</li>
                <li>Payroll services</li>
                <li>Assessment and training services</li>
                <li>Training and Placement cell Outsourcing services</li>
                </ul>
                <p></p>
                <p>We serve businesses of all sizes – small, medium, and large multinational corporates, and in multiple domains. SBCEL Consulting helps organisations address important HR outsourcing requirements by aligning the human resources and business strategies. We manage critical HR processes, thus bringing significant operational excellence and seamless integrated delivery.</p>
                <p>Our human resource outsourcing services will include activities throughout the entire duration of the employee life-cycle, right from on boarding to retirement or outplacement. We partner with client organisations to fully assist them in the transformation of their HR model to bring about maximum value to the business.</p>
                <p></p>
                <h4>REDUCE COSTS AND ENHANCE EFFICIENCY</h4>
                <p>In the competitive marketplace today, organisations across the globe are looking at all possible opportunities to bring down operational costs and to increase efficiency. The human resources department is also a key player in this scenario. Organisations have to deal with the high pressure of cost reduction, employee disengagement, and high rates of attrition.</p>
                <p>Human resources outsourcing is the most sustainable method to manage costs and to facilitate the organisation to focus on core operations and strategic initiatives instead of wasting time in the routine transactional activities of human resource management.</p>
                <p></p>
                <h4>CUSTOMIZED RECRUITMENT PROCESS OUTSOURCING SOLUTIONS</h4>
                <p>Recruiting involves a significant cost for organisations. But with ingenious<strong>recruitment solutions</strong>such as what is offered by SBCEL Consultants, companies can bring down the cost of recruiting the right talent for their staffing requirements.</p>
                <p>Recruitment process outsourcing involves an employer transferring all or part of their recruitment processes to an external provider like SBCEL Consultants. We will then manage the entire gamut of recruiting and hiring, or just the chosen processes as you desire. Essentially, we serve as an extension of your own human resources department within the organisation.</p>
                <p><strong>Here are some of the RPO services that we provide:</strong></p>
                <ul>
                <li>Research of candidates</li>
                <li>Tracking compliance</li>
                <li>Training hiring managers</li>
                <li>Audits and reporting</li>
                <li>Consulting on technology</li>
                <li>Process mapping</li>
                </ul>
                <p></p>
                <p>If you’re looking for a customised solution that is somewhat different from that provided by a staffing agency or head hunter, then recruitment processing outsourcing may be the ideal choice for you.</p>
                <p><strong>Payroll Management services</strong></p>
                <hr />
                <p>Service is everything to us and it should be everything to you too! Our clients are not just another number to us; they are people with specific needs and business goals. As one of the Top Payroll Outsourcing Companies in India your priorities equal our responsibilities and our achievement equals your fulfilment.</p>
                <p>Whether you currently process payroll in-house, outsource it or work with an accountant our Payroll Management services are just what you need. You experience advanced payroll management services that will save you time and money while mitigating risk and ensuring compliance.</p>
                <p>With us the efficient service partner providing Payroll Management services – outsourced payroll and benefits processing, time and attendance solutions and compliance management.</p>
                <p></p>
                <p>BENEFITS OF OUTSOURCING PAYROLL</p>
                <p><strong>Some of the benefits of outsourcing payroll management include the following:</strong></p>
                <ul>
                <li>Fast turnaround</li>
                <li>Assistance throughout the entire life-cycle of the employee, right from the day of recruitment to exit</li>
                <li>Streamlining of all payroll processes</li>
                <li>Reduced investment in IT infrastructure</li>
                <li>Well thought-out resource deployment</li>
                <li>Strict adherence to compliance and statutory requirements</li>
                <li>Error-free documentation and reports</li>
                <li>Solid business intelligence that helps top management with their strategic initiatives</li>
                <li>Low-risk of penalties due to non-compliance</li>
                <li>Speedy and efficient resolution of all employee issues</li>
                </ul>
                <p></p>
                <p>CUSTOMISED SOLUTIONS TO ALL COMPANIES</p>
                <p>Managing payroll entirely within your organisation can be a headache because of stiff penalties for non-compliance to statutory requirements, and other issues that are not straightforward. It may seem simple from the outside, but many companies that do their own payroll processing ultimately discovers that it may not be all that cheap and simple — especially when you consider the time that is spent in managing the entire process.</p>
                <p>For many organisations, payroll outsourcing offers an attractive alternative to in-house processing. It can provide a simpler, affordable way of paying employees, filing taxes, and performing other activities associated with payroll administration.</p>
                <p>We provide innovative and completely customised solutions to all organisations regardless of their size and the domain that they are in. Our payroll solutions will directly address all the requirements of payroll management. We help businesses enhance their competitive advantage with the help of customised solutions built on the foundation of vast domain experience, qualified professionals, and advanced technology. This allows us to offer our clients the confidence to meet all their business challenges.</p>
                <p></p>
                <h3><strong><i className="fa fa-hand-o-right"></i> Human Resource Management System</strong></h3>
                <hr />
                <p>A<strong>Human Resource Management System</strong>, or HRMS as it is commonly referred to, is a combination of all the systems and processes that are necessary to efficiently manage the HR Department of an organisation. There are many routine and tedious activities in HR that should ideally be handled by technology.</p>
                <p>By using an<strong>HRMS software</strong>, you can significantly reduce the manual workload required to carry out these tasks. SBCEL offers an end-to-end enterprise HRMS web based application which can manage the entire gamut of HR functions.</p>
                <p><strong>Our HR software will take care of all the following activities</strong></p>
                <ul>
                <li>On-boarding</li>
                <li>Leave</li>
                <li>Attendance</li>
                <li>Payroll</li>
                <li>Reimbursement</li>
                <li>E-TDS</li>
                <li>Statutory &amp; Compliance</li>
                <li>Reports and Online employee self-service to access Pay slip</li>
                <li>IT declaration</li>
                <li>HRIS – Human Resources Information System</li>
                </ul>
                <p>With a reliable HRIS, businesses can transform the way they leverage the human capital in their organisations, and reduce the gap between employee productivity and technology. Save a considerable amount of time, money, and resources in the following activities:</p>
                <ul>
                <li>Pre-on boarding &amp; on boarding Management.</li>
                <li>Employment Letter Generation with Unique ID.</li>
                <li>Employee Database Management – Update Employee Details.</li>
                <li>Performance Management, Alerts &amp; Reminders, Memos, Bulletin.</li>
                <li>HR Reports, Document Centre to manager HR Policy Manuals, Handbooks, HRMS forms</li>
                </ul>
                <p></p>
                <p>LEAVE AND ATTENDANCE UPDATES</p>
                <p>Your organisation needs to monitor the working days and off days of all employees in order to effectively manage absenteeism. If the employees have any queries, they must be logged and addressed as soon as possible to maintain work satisfaction.</p>
                <p>Our HR software will manage all aspects of leaves, attendance, shifts, and trouble tickets for employee queries.</p>
                <p></p>
                <h3><i className="fa fa-hand-o-right"></i> <strong>PAYROLL PROCESS MANAGEMENT</strong></h3>
                <hr />
                <p>Well-equipped<strong>payroll management software</strong>can significantly relieve your HR department from a lot of monotonous manual work. Our<strong>payroll software</strong>will process salaries quickly and accurately.<br />
                The following are just some of the benefits that you can enjoy with a good<strong>payroll management system</strong></p>
                <ul>
                <li>Fast and efficient processing of payroll</li>
                <li>Management of reimbursement claims and loans</li>
                <li>Generate E-TDS and Form 16 quickly</li>
                <li>Easy way to view, access, and print payslips</li>
                <li>Customised payroll reports as and when required</li>
                </ul>
                <p></p>
                <p>STATUTORY AND COMPLIANCE MANAGEMENT</p>
                <p>Being compliant to the numerous statutes and labour laws can be a gargantuan task in itself, but adding to the complexity is the necessity to generate e-challans as per the required standards. Doing this manually could be a nightmare.</p>
                <p><strong>Our software will considerably ease the load by</strong></p>
                <ul>
                <li>Automating the format to comply with e-challan generation standards</li>
                <li>Generating customised statutory reports as required</li>
                <li>Generating labour law forms as required</li>
                </ul>
                
                <p><strong>Employee self-service (ESS)</strong></p>
                <p>Employees often need to access their work-related information for various purposes. It won’t be easy to do this manually in organisations which have a large number of employees. A web-based employee self-service portal could be the perfect solution for employees to have secure access to their own personal information online. This is another facility that our HR software provides.</p>
                <p></p>
                <h3><strong><i className="fa fa-hand-o-right"></i> HR AND PAYROLL SOFTWARE</strong></h3>
                <hr />
                <p>We take care of all the aspects of the different HR and payroll activities of your organisation through our software.</p>
                <p><strong>The following are just a few of the functionalities that we offer:</strong></p>
                <ul>
                <li>Adding statutory details</li>
                <li>Bulk import of employee details</li>
                <li>Payroll processing</li>
                <li>Salary statement generation</li>
                <li>Exporting bank statements</li>
                <li>Bank advice</li>
                <li>Payroll and statutory reports generation</li>
                <li>ESS -employees can access, view, or request personal or company related information 24/7</li>
                <li>Employee login facility</li>
                <li>Employee query management</li>
                <li>Statutory outsourcing</li>
                <li>Payroll cycle management</li>
                </ul>
                <p></p>
                <p>To learn more about how to implement HR software in your organisation, get in touch with us right away.</p>
                </div>
            </Row>
        </Container>
    )
}

export default HrServices;